import { makeStyles } from '@material-ui/core';
import c from 'classnames';
import Img from 'gatsby-image';
import React from 'react';
// import { BlogPostQuery_mdx_frontmatter_images_childImageSharp_fluid as FluidQuery } from '../gatsby-queries';

const useStyles = makeStyles((theme) => ({
   leftImg: {
      padding: `0px ${theme.spacing(2)}px ${theme.spacing(1)}px 0px`,
      float: 'left'
   },
   centerImg: {
      display: 'block',
      padding: `0px 0px ${theme.spacing(2)}px 0px`,
      margin: 'auto'
   },
   rightImg: {
      padding: `0px 0px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
      float: 'right',
      textAlign: 'right'
   },
   mobile: {
      width: '100% !important'
   }
}));

export const SafeFluid = (f) => {
   return {
      aspectRatio: f ? f.aspectRatio || 1 : 1,
      sizes: f ? f.sizes || '' : '',
      src: f ? f.src || '' : '',
      srcSet: f ? f.srcSet || '' : '',
      tracedSVG: f ? f.tracedSVG || '' : ''
   };
};

export const MDXSharpImg = ({ width, fluid, align }) => {
   const classes = useStyles();
   return (
      <span
         className={c({
         [classes.leftImg]: align === 'left',
         [classes.rightImg]: align === 'right',
         [classes.centerImg]: align === 'center'
         })}
         style={{ width: width || '40%' }}
      >
         <Img fluid={fluid} />
      </span>
   );
};

export const MDXSrcImg = ({ src, width, align }) => {
   const classes = useStyles();
   return (
      <img alt=""
         className={c({
         [classes.leftImg]: align === 'left',
         [classes.rightImg]: align === 'right',
         [classes.centerImg]: align === 'center'
         })}
         style={{ width: width || '40%' }}
         src={src}
      />
   );
};